import { Route } from '@angular/router';
import { MerchantGuard } from '@core/auth/guards/merchant.guard';
import { LanguageResolver } from '@core/resolver/language.resolver';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { paths } from './app.paths';
import { PagePermissionGuard } from '@core/auth/guards/permission.guard';

/**
 * An array of Route objects that define the routes for the application.
 */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    { path: paths.null, pathMatch: 'full', redirectTo: paths.dashboard },

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: paths.auth.signedInRedirect, pathMatch: 'full', redirectTo: paths.dashboard },

    // Auth routes for guests or users
    {
        path: paths.null,
        canActivate: [],
        canActivateChild: [],
        component: LayoutComponent,
        resolve: {
            language: LanguageResolver
        },
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: paths.auth.redirect,
                loadChildren: () => import('app/pages/auth/redirect/redirect.routes'),
                data: {
                    pageName: 'auth-redirect'
                }
            },
            {
                path: paths.auth.resetPassword,
                loadChildren: () => import('app/pages/auth/reset-password/reset-password.routes'),
                data: {
                    pageName: 'auth-reset-password'
                }
            }
        ]
    },

    // Auth routes for guests
    {
        path: paths.null,
        canActivate: [NoAuthGuard], // The user must be logged out to access these routes
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        resolve: {
            language: LanguageResolver
        },
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: paths.auth.confirmationRequired,
                loadChildren: () => import('app/pages/auth/confirmation-required/confirmation-required.routes'),
                data: {
                    pageName: 'auth-confirmation-required'
                }
            },
            {
                path: paths.auth.emailVerified,
                loadChildren: () => import('app/pages/auth/email-verified/email-verified.routes'),
                data: {
                    pageName: 'auth-email-verified'
                }
            },
            {
                path: paths.auth.forgotPassword,
                loadChildren: () => import('app/pages/auth/forgot-password/forgot-password.routes'),
                data: {
                    pageName: 'auth-forgot-password'
                }
            },
            {
                path: paths.auth.signIn,
                loadChildren: () => import('app/pages/auth/sign-in/sign-in.routes'),
                data: {
                    pageName: 'auth-sign-in'
                }
            },
            {
                path: paths.auth.signUp,
                loadChildren: () => import('app/pages/auth/sign-up/sign-up.routes'),
                data: {
                    pageName: 'auth-sign-up'
                }
            },
            {
                path: paths.auth.mfa,
                loadChildren: () => import('app/pages/auth/mfa/mfa.routes'),
                data: {
                    pageName: 'auth-mfa'
                }
            }
        ]
    },

    // Auth routes for authenticated users
    {
        path: paths.null,
        canActivate: [AuthGuard], // The user must be logged in to access these routes
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            language: LanguageResolver,
            initialData: InitialDataResolver
        },
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: paths.auth.signOut,
                loadChildren: () => import('app/pages/auth/sign-out/sign-out.routes'),
                data: {
                    pageName: 'auth-sign-out'
                }
            },
            {
                path: paths.auth.offboarding,
                loadChildren: () => import('app/pages/auth/offboarding/offboarding.routes'),
                data: {
                    pageName: 'auth-offboarding'
                }
            },
            {
                path: paths.auth.openMerchant,
                loadChildren: () => import('app/pages/auth/open-merchant/open-merchant.routes'),
                data: {
                    pageName: 'auth-open-merchant'
                }
            },
            {
                path: paths.onboarding.name,
                loadChildren: () => import('app/pages/onboarding/name/name.routes'),
                data: {
                    pageName: 'onboarding-name'
                }
            },
            {
                path: paths.onboarding.start,
                loadChildren: () => import('app/pages/onboarding/start/start.routes'),
                data: {
                    pageName: 'onboarding-start'
                }
            },
            {
                path: paths.onboarding.domain,
                loadChildren: () => import('app/pages/onboarding/domain/domain.routes'),
                data: {
                    pageName: 'onboarding-domain'
                }
            },
            {
                path: paths.onboarding.online,
                loadChildren: () => import('app/pages/onboarding/online/online.routes'),
                data: {
                    pageName: 'onboarding-online'
                }
            },
            {
                path: paths.onboarding.address,
                loadChildren: () => import('app/pages/onboarding/address/address.routes'),
                data: {
                    pageName: 'onboarding-address'
                }
            },
            {
                path: paths.onboarding.phone,
                loadChildren: () => import('app/pages/onboarding/phone/phone.routes'),
                data: {
                    pageName: 'onboarding-phone'
                }
            },
            {
                path: paths.onboarding.done,
                loadChildren: () => import('app/pages/onboarding/done/done.routes'),
                data: {
                    pageName: 'onboarding-done'
                }
            },
            {
                path: paths.onboarding.language,
                loadChildren: () => import('app/pages/onboarding/language/language.routes'),
                data: {
                    pageName: 'onboarding-language'
                }
            }
        ]
    },

    // Connect and one-time onboarding routes
    {
        path: paths.null,
        canActivate: [  // The user must be logged in and a merchant must be open to access these routes
            AuthGuard,
            MerchantGuard
        ],
        canActivateChild: [
            AuthGuard,
            MerchantGuard
        ],
        component: LayoutComponent,
        resolve: {
            language: LanguageResolver,
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: paths.connect,
                loadChildren: () => import('@pages/connect/connect.routes'),
                data: {
                    pageName: 'connect'
                }
            },
            {
                path: paths.onboarding.congrats,
                loadChildren: () => import('app/pages/onboarding/congrats/congrats.routes'),
                data: {
                    pageName: 'onboarding-congrats'
                }
            },
            {
                path: paths.onboarding.payment,
                loadChildren: () => import('app/pages/onboarding/payment/payment.routes'),
                data: {
                    pageName: 'onboarding-payment'
                }
            },
            {
                path: paths.onboarding.openai,
                loadChildren: () => import('app/pages/onboarding/openai/openai.routes'),
                data: {
                    pageName: 'onboarding-openai'
                }
            },
            {
                path: paths.onboarding.ai,
                loadChildren: () => import('app/pages/onboarding/ai/ai.routes'),
                data: {
                    pageName: 'onboarding-ai'
                }
            },
            {
                path: paths.onboarding.media,
                loadChildren: () => import('app/pages/onboarding/media/media.routes'),
                data: {
                    pageName: 'onboarding-media'
                }
            },
            {
                path: paths.onboarding.aboutUs,
                loadChildren: () => import('app/pages/onboarding/about-us/about-us.routes'),
                data: {
                    pageName: 'onboarding-about-us'
                }
            }
        ]
    },

    // Admin routes
    {
        path: paths.null,
        canActivate: [  // The user must be logged in and a merchant must be open to access these routes
            AuthGuard,
            MerchantGuard,
            PagePermissionGuard
        ],
        canActivateChild: [
            AuthGuard,
            MerchantGuard,
            PagePermissionGuard
        ],
        runGuardsAndResolvers: 'always',
        component: LayoutComponent,
        resolve: {
            language: LanguageResolver,
            initialData: InitialDataResolver
        },
        children: [
            {
                path: paths.welcome,
                loadChildren: () => import('@pages/welcome/welcome.routes'),
                data: {
                    pageName: 'welcome'
                }
            },
            {
                path: paths.dashboard,
                loadChildren: () => import('@pages/dashboard/dashboard.routes'),
                data: {
                    pageName: 'dashboard',
                    pagePermissionName: 'dashboard'
                }
            },
            {
                path: paths.merchant,
                loadChildren: () => import('@pages/merchant/merchant.routes'),
                data: {
                    pageName: 'merchant',
                    pagePermissionName: 'merchant'
                }
            },
            {
                path: paths.customers,
                loadChildren: () => import('@pages/customers/customers.routes'),
                data: {
                    pageName: 'customers',
                    pagePermissionName: 'customers'
                }
            },
            {
                path: paths.products,
                loadChildren: () => import('@pages/products/products.routes'),
                data: {
                    pageName: 'products',
                    pagePermissionName: 'products'
                }
            },
            {
                path: paths.website,
                loadChildren: () => import('@pages/website/website.routes'),
                data: {
                    pageName: 'website',
                    pagePermissionName: 'website'
                }
            },
            {
                path: paths.categories,
                loadChildren: () => import('@pages/categories/categories.routes'),
                data: {
                    pageName: 'categories',
                    pagePermissionName: 'products'
                }
            },
            {
                path: paths.orders,
                loadChildren: () => import('@pages/orders/orders.routes'),
                data: {
                    pageName: 'orders',
                    pagePermissionName: 'orders'
                }
            },
            {
                path: paths.settings,
                loadChildren: () => import('@pages/settings/settings.routes'),
                data: {
                    pageName: 'settings',
                    pagePermissionName: 'settings'
                }
            },
            {
                path: paths.agenda,
                loadChildren: () => import('@pages/agenda/agenda.routes'),
                data: {
                    pageName: 'agenda',
                    pagePermissionName: 'agenda'
                }
            },
            {
                path: paths.import_picture,
                loadChildren: () => import('@pages/import-picture/import-picture.routes'),
                data: {
                    pageName: 'import-picture',
                    pagePermissionName: 'products'
                }
            },
            {
                path: paths.import_file,
                loadChildren: () => import('@pages/import-file/import-file.routes'),
                data: {
                    pageName: 'import-file',
                    pagePermissionName: 'products'
                }
            },
            {
                path: paths.import_woocommerce,
                loadChildren: () => import('@pages/import-woocommerce/import-woocommerce.routes'),
                data: {
                    pageName: 'import-woocommerce',
                    pagePermissionName: 'products'
                }
            },
            {
                path: paths.import_feed,
                loadChildren: () => import('@pages/import-feed/import-feed.routes'),
                data: {
                    pageName: 'import-feed',
                    pagePermissionName: 'products'
                }
            },
            {
                path: paths.helpCenter,
                loadChildren: () => import('@pages/help-center/help-center.routes'),
                data: {
                    pageName: 'help-center',
                    pagePermissionName: 'help-center'
                }
            },
            {
                path: paths.blogs,
                loadChildren: () => import('@pages/blogs/blogs.routes'),
                data: {
                    pageName: 'blogs',
                    pagePermissionName: 'blogs'
                }
            },
            {
                path: paths.coupons,
                loadChildren: () => import('@pages/coupons/coupons.routes'),
                data: {
                    pageName: 'coupons',
                    pagePermissionName: 'coupons'
                }
            }
        ]
    }
];
